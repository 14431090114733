import styled from "styled-components";


export const ProductsDiv = styled.div`
    background-color: white;
    position: fixed;
    top: 70px;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 80%;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 20px;
    word-wrap: break-word;
  z-index: 10;
    &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

  &::-webkit-scrollbar-track {
    background-color: #555;
  }
    
`

export const ProductsInfoDiv = styled.div`
    width: 100%;
    min-height: 100px;
    background-color: white;
    margin-top: 20px;
    padding: 10px;
    overflow: auto;
    word-wrap: break-word;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
    &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

  &::-webkit-scrollbar-track {
    background-color: #555;
  }



`

export const DoneDiv = styled.div`
position: absolute;
right: 10px;
top: 10px;
button{
  width: 40px;
  height: 40px;
  border-radius: 10px;
}
`

export const EditProductDiv = styled.form`
    width: 100%;
    min-height: 100px;
    
`

export const EditProductInput = styled.input`
    margin-top: 10px;
`

export const EditProductButton = styled.button`
    margin-top: 10px;
    height: 40px;
    width: 90px;
`

export const InStockP = styled.p`
color: ${({ inStock }) => inStock ? "green" : "red"};
`

export const SortSpan = styled.span`
  cursor: pointer;
  font-weight: bold;
`


export const SortDiv = styled.div`
  width: 100%;
  min-height: 30px;
  padding: 20px;
`


export const EditImgInput = styled.input`
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 3px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  width: 100%;
  margin-top: 20px;
`


export const SearchProducts = styled.input`
  width: 300px;
  position: relative;
  height: 40px;
  margin-top: 10px;
  border-radius:20px;
  outline: none;
  padding-left: 10px;
  @media(max-width:500px){
    width: 100%;
  }
`
export const SearchDivIconDiv = styled.div`
  position: absolute;
  font-size: 25px;
  top: 40px;
  left: 280px;
  cursor: pointer;

  @media(max-width:441px){
    left: 70%;
  }
`