import styled from "styled-components";

export const AddUserFormDiv = styled.form`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 400px;
    height: 350px;
    background-color: white;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    z-index: 10;
    @media(max-width:500px){
        width: 80%;
    }
`
export const AddUsersInput  = styled.input`
    width: 100%;
    height: 40px;
    border: none;
    outline:none;
    border-radius: 20px;
    padding: 10px;
    background-color: #eee;
    margin-top: 20px;
`
export const AddUserInputDiv = styled.div`
    width: 100%;
    margin-top: 20px;
`
export const ButtonInForm = styled.button`
	width: 80%;
	height: 40px;
	margin-top:50px;
	border-radius:50px;
	border:none;
	font-size: 19px;
	transition: 0.5s;
	background-color: #5cb85c;
    cursor: pointer;
	&:hover{
	box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
	transform: scale(1.05);	};
    `

    export const CheckInput = styled.input`
        margin-top: 20px;
    `