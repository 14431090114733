import styled from "styled-components";


export const MainDiv = styled.div`
width: 330px;
min-height: 280px;
display: flex;
flex-direction: column;
position: fixed;
bottom: 10px;
right: 10px;
background-color: white;
border-radius: 30px;
box-shadow: 0px 0px 8px 4px rgba(0,0,0,0.44);
z-index: 99999999999;
@media(max-width: 350px){
    width: 90%;
    left: 0;
    right: 0;
    margin: auto;
}

`

export const CloseDiv = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    opacity: 0.7;
    color: white;
    font-weight: 700;
    cursor: pointer;
`

export const HeaderDiv = styled.div`
    width: 100%;
    height: 30%;
    padding-top: 30px;
    display: flex;
    justify-content: center;
    img{
        height: 60px;
    }
    @media(max-width: 400px){
        height: 20%;
        img{
        height: 50px;
    }
}
`
export const BodyDiv = styled.div`
    width: 100%;
    height: 40%;
    padding: 20px;
    text-align: center;

    span{
        font-weight: 900;
        font-size: 18px;
    }
    @media(max-width: 400px){
        padding: 5px 20px;
    }
`

export const FooterDiv = styled.div`
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
`

export const MyButton = styled.button`
    width: 90%;
    height: 50px;
    background-color: black;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 10px;

`