import { Link } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../assets/colors";

export const MainMenuDiv = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  z-index: 99999;
  background-color: ${({ $scrolled }) => ($scrolled ? "#4c4e4d" : "none")};
  transition: 0.2s;
  top: 0;
  font-family: 'Lato', sans-serif;
  
`;

export const MenuTwoParts = styled.div`
  width: 50%;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const NameOfAPerson = styled(Link)`
  width: 100%;
  height: 40px;
  font-size: 32px;
  padding: 0 40px;
  color: white;
  margin-left: 70px;
  text-decoration: none;
  font-weight: 300;
  font-family: 'Lato', sans-serif;
  z-index: 2;
  @media (max-width: 400px) {
  padding: 0 10px;
    
  }
`;

export const ProfOfAPerson = styled(Link)`
  width: 100%;
  height: 50px;
  font-size: 20px;
  padding-top: 10px;
  padding-left: 40px;
  margin-left: 70px;
  color: gray;
  font-family: 'Lato', sans-serif;

  text-decoration: none;
  @media (max-width: 400px) {
  padding: 8px 10px;
    
  }
`;

export const MenuPages = styled.div`
  width: 20%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ $activ }) => ($activ ? "#08229f" : "none")};
  border-radius: 30px;
  text-decoration: none;
  z-index: 100;

  @media (max-width: 800px) {
   display: none;
  }
`;

export const MenuLinks = styled(Link)`
  color: white;
  font-size: 19px;
  font-weight: 300;
  text-decoration: none;
  font-family: 'Lato', sans-serif;
  
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1010px) {
    font-size: 16px;
  }
  @media (max-width: 854px) {
    font-size: 13px;
  }
`;

export const HoverOpenMenu = styled.div`
  width: 300px;
  background-color: none;
  z-index: 999999;
  transition: 1s;
  display: ${({ open }) => (open ? "block" : "none")};
  position: absolute;
  padding-top: 80px;
  top: 20px;
  text-align: center;
  border-radius: 0 0 20px 20px;
  border-radius: ${({ open }) => (open ? "0 0 20px 20px" : "20px")};

  padding: 50px 20px 20px 20px;
  margin-left: -110px;
`;

export const HoverMenuPages = styled(Link)`
  width: 90%;
  margin: 0 auto;
  height: 35px;
  background-color: #4c4e4d;
;
  display: flex;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  border-radius: ${({ open }) => (open ? "0 0 20px 20px" : "20px")};


`;

export const HoverMenuLinks = styled.div`
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-radius: ${({ open }) => (open ? "0 0 20px 20px" : "20px")};

  &:hover {
    text-decoration: underline;
  }
 
`;


export const IconsDiv = styled.div`
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  color: white;
  gap: 10px;
  font-size: 22px;  
  @media(max-width: 800px){
    right: 30px;
  }
  @media(max-width: 350px){
    right: 5px;
  gap: 1px;

  }

  a{
    text-decoration: none;
    cursor: pointer;
    color: white;
    margin-top: 7px;
    transition: transform 0.5s;
    &:hover{
      opacity: 0.8;
      transform: scale(1.3);
      color: #08229f;
      @media(max-width: 800px){
        opacity: 1;
        transform: scale(1);
        color: white;
    }

  }
  img{
    width: 30px;
    height: 30px;
    color: white;
  }
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;  
    transition: transform 0.5s;
    font-size: 20px;


    &:hover{
      opacity: 0.7;
      transform: scale(1.1);
      color: #08229f;
      @media(max-width: 800px){
        opacity: 1;
        transform: scale(1);
        color: white;
  }
    }
    }
  }
`

export const RespMenuDiv = styled.div`
  width: 100%;
  min-height:${({ $openResMenu }) => $openResMenu ? "300px" : "0"};

  position: fixed;
  top: 80px;
  z-index: ${({ $openResMenu }) => $openResMenu ? "11" : "0"};
  transition: 0.5s;
  opacity: ${({ $openResMenu }) => $openResMenu ? "1" : "0"};
  width:  ${({ $openResMenu }) => $openResMenu ? "100%" : "1px"};
  background-color: #212322;

  padding-bottom: 50px;
  @media(min-width: 800px){
    display: none;
  }

`

export const MenuLinksResp = styled(Link)`
  width: 80%;
  height: 60px;
  margin: 0 auto;
  border-bottom: 1px solid white;
  color: white;
  display: flex;
  text-decoration: none;
  cursor: pointer;
  display: ${({ $openResMenu }) => $openResMenu ? "block" : "none"};
  padding-top: 20px;
  span{
    font-size: 15px;
  }
`

export const RespMenuIcon = styled.div`
  position: fixed;
  top: 25px;
  left: 30px;
  font-size: 30px;
  color: white;
  z-index: 1225555555;
  display: none;
  cursor: pointer;
  @media(max-width: 800px){
    display: block;
  }
`

export const PagesDiv = styled.div`
  width: 100%;
    border-radius: ${({ open }) => (open ? "0 0 20px 20px" : "20px")};


  background-color: #4c4e4d;
  
  `

export const LanguageDiv = styled.div`
    width: 70px;
    cursor: pointer;
    border-radius:7px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  position: absolute;
  padding: 0 0 20px 0;
  top: 70px;
  left: -15px;
  background-color: ${({ scrolled }) => scrolled ? " #212322" : "none"};
  @media(max-width: 350px){
    left: 0;


  }
  

`
export const HeaderLanguage = styled.div`
    width: 70px;
    height: 30px;
    border-radius:7px;
    cursor: pointer;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    
`

export const LangImg = styled.img`
    width: 35px;
    height: 35px;
    border-radius: 50%;

`
export const LangText = styled.span`
    margin-left:15px;
`
