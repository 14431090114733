import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ImgDiv, MainDiv, ProductsDiv, ProductsImgDiv, TitleText } from "./styled";
import { useTranslation } from "react-i18next";

export const PaintingsPage = () => {
    const [obj, setObj] = useState([]);
    const [img, setImg] = useState(null);
    const [isVisible, setIsVisible] = useState([]);
    const [lang, setLang] = useState(localStorage.getItem('lang') || 'en');
    const { t } = useTranslation();

    // Проверка размера экрана
    const isSmallScreen = useMemo(() => window.innerWidth < 900, []);

    // Получаем данные с сервера
    const fetchData = useCallback(async () => {
        try {
            const [paintingsRes, bgcRes] = await Promise.all([
                fetch("https://server.mayyanart.com/admin/paintings").then(res => res.json()),
                fetch("https://server.mayyanart.com/bgc-get", {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ name: "products" })
                }).then(res => res.json())
            ]);

            setObj(paintingsRes.reverse());
            setImg(bgcRes.img);
            setIsVisible(Array(paintingsRes.length).fill(false));
        } catch (error) {
            console.error("Ошибка загрузки данных:", error);
        }
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    useEffect(() => {
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                const index = parseInt(entry.target.getAttribute('data-index'), 10);
                if (entry.isIntersecting) {
                    setIsVisible((prev) => {
                        const updatedVisibility = [...prev];
                        updatedVisibility[index] = true;
                        return updatedVisibility;
                    });
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.1,
        });

        obj.forEach((_, index) => {
            const element = document.getElementById(`products-${index}`);
            if (element) observer.observe(element);
        });

        return () => observer.disconnect();
    }, [obj]);

    return (
        <MainDiv>
            <ImgDiv>{img && <img src={`https://server.mayyanart.com/uploads/${img?.src}`} alt="Background" />}</ImgDiv>
            <TitleText><span>Paintings</span></TitleText>
            <ProductsDiv>
                {obj.map((e, i) => (
                    e.instock && (
                        <ProductsImgDiv
                            id={`products-${i}`}
                            data-index={i}
                            key={e._id}
                            style={{
                                opacity: isVisible[i] ? 1 : 0,
                                transform: isVisible[i] ? 'translateX(0)' : (i % 2 === 0 ? 'translateX(-30px)' : 'translateX(30px)'),
                                transition: 'opacity 1.5s, transform 1.5s',
                            }}
                            $text={
                                lang === "ru" ? e.titleRU :
                                lang === "en" ? e.titleEN :
                                e.titleAM
                            }
                            to={`/product/id/${e._id}`}
                        >
                            <img src={`https://server.mayyanart.com/uploads/${e.imgs[0]}`} alt={e.titleEN} />
                        </ProductsImgDiv>
                    )
                ))}
            </ProductsDiv>
        </MainDiv>
    );
};
