import styled from "styled-components";

export const MainDiv = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
    @media(max-width: 700px){

            width: 80%;
        }
        
    }
   
`