import React from "react";
import { MainDiv } from "./styled";
import logo from "../../assets/images/logo_2.gif"

export const LoadingPage = () => {
    return(
        <MainDiv>
            <img src={logo} ></img>
        </MainDiv>
    )
}