import styled from "styled-components";



export const RamkaDiv = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 600px;
    width: 400px;
    text-align: center;
    overflow-y: auto;
    z-index: 2;
    background-color: white;
    border-radius: 30px;
    @media(max-width:600px){
        width: 80%;
    }
`

export const DepartmentDiv = styled.div`
    width: 90%;
    margin: 50px auto;
    min-height: 300px;
`


export const NameDivs = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-around;
    height: 50px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
`

export const Name = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Activ = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

export const SaveBut = styled.button`
    width: 100px;
    height: 50px;
    border-radius: 30px;
    background-color: gray;
    color: white;
    border: none;
    cursor: pointer;
    margin-bottom: 50px;

`

export const HrefInput = styled.input`
    width: 90%;
    margin: 10px auto;
    height: 40px;
    outline: none;
    border-radius: 20px;
    padding-left: 10px;
    font-size: 20px;
    
`