import React, { useEffect, useState } from "react";
import {
  FooterDiv,
  Icons,
  IconsForm,
  LinkA,
  UlMenu,
  MenuLi,
  MenuLinks,
  P,
} from "./styled";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next'

export const Footer = () => {
  const [color, setColor] = useState("red")
  const loc = useLocation()
  const parts = loc.pathname.split("/");
  const firstWord = "/" + parts[1];
  const { t } = useTranslation()
  const [data, setData] = useState()

  // useEffect(() => {
  //   switch (firstWord) {
  //     case "/products": setColor("#212322")
  //       break;
  //     case "/": setColor("#ab9691")
  //       break;
  //     case "/contact": setColor("#212322")
  //       break;
  //     case "/product": setColor("#212322")
  //       break;
  //     case "/about": setColor("#212322")
  //       break;
  //       case "/paintings": setColor("#212322")
  //       break;
  //       case "/daily-scathes": setColor("#212322")
  //       break;
  //       case "/wall-art": setColor("#212322")
  //       break;
  //       case "/nft": setColor("#212322")
  //       break;
  //     default: setColor("#4d5153")
  //   }
  // }, [loc, firstWord, parts])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://server.mayyanart.com/network-get")
        const res = await req.json()
        setData(res.dep)
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()

  }, [])
  return (
    <>
      <FooterDiv style={{ backgroundColor: "#4c4e4d" }}>

        <IconsForm>
          <Icons>
            {data?.inst[0].activ && <LinkA to={data?.inst[0].href} target="_blank">
              <RiInstagramFill />
            </LinkA>}


          </Icons>
          <Icons>
            {data?.fb[0].activ && <LinkA to={data?.fb[0].href}  target="_blank">
              <FaFacebookF />
            </LinkA>}

          </Icons>

        </IconsForm>
        <UlMenu>
          <MenuLi>
            <MenuLinks to={"/"}>{t("Home")}</MenuLinks>
          </MenuLi>
          <MenuLi>
            <MenuLinks to={"/about"}>{t("About")}</MenuLinks>
          </MenuLi>
          <MenuLi>
            <MenuLinks to={"/contact"}>{t("Contact")}</MenuLinks>
          </MenuLi>
          <MenuLi>
            <MenuLinks to={"/paintings"}>{t("Products")}</MenuLinks>
          </MenuLi>
        </UlMenu>
        <P>©2024 R'SOFT | {t("All Rights Reserved")}</P>
      </FooterDiv>
    </>
  );
};
