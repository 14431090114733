import styled from "styled-components";

export const ImgDiv = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #646765;
  height: 100vh;
  img{
    width: 100%;
    margin-top: -20%;
  }
`

export const MainDiv = styled.div`
  background-color: #646765;
  width: 100%;
  padding: 100px 0;
`

export const BodyTitleDiv = styled.div`
  width: 100%;
  text-align: center;

  span{
    color: white;
    font-size: 40px; 
  }
`

export const ContactForm = styled.form`
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media(max-width: 850px){
    width: 90%;
  }
  @media(max-width: 600px){
    flex-direction: column;
  }
  
`

export const NameInput = styled.input`
  width: 48%;
  height: 40px;
  background:none;
  color: white;
  border: 2px solid white;
  outline: none;
  margin-top: 50px;
  text-align: center;
  &::placeholder {
  color: white; 
  font-style: italic; 
}
@media(max-width: 600px){
  width: 100%;
  margin-top: 20px;


  }

`


export const EmailInput = styled.input`
  width: 100%;
  height: 40px;
  background:none;

  color: white;
  border: 2px solid white;
  outline: none;
  margin-top: 20px;
  text-align: center;
  &::placeholder {
  color: white; 
}
`

export const MessageInput = styled.textarea`
  width: 100%;
  height: 80px;
  background:none;

  color: white;
  border: 2px solid white;
  outline: none;
  margin-top: 20px;
  text-align: center;
  padding:20px;
  &::placeholder {
  color: white; 
}
`

export const MyButton = styled.button`
  padding: 10px;
  text-align: center;
  margin: 0 auto;
  margin-top: 30px;
  background-color: none;
  cursor: pointer;
  background:none;

  border: 1px solid white;
  color: white;
  transition: 0.5s;
  &:hover{
  border: 1px solid black;
  background-color: white;
  color: black;
  font-weight: bold;
  transform: scale(1.1);


  }

`