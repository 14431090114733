import styled from "styled-components";

export const MainDiv = styled.div`
  width: 100%;

`;
export const HomeImgDiv = styled.div`
 width: 100%;
    height: 100vh;
    overflow: hidden;
    background-color: #444b53;
    @media(max-width: 600px){
      height: auto;
    }
`
export const HomeImg = styled.img`
  width: 100%;
  background-color: #444b53;


`

export const ImgDiv = styled.div`
  width: 65%;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
  @media (max-width: 650px) {
    width: 80%;
  }
  @media (max-width: 450px) {
    width: 90%;
  }
`;

export const Img1 = styled.img`
  width: 100%;
  height: 100%;
`;
