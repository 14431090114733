import styled from "styled-components";

export const MainDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color:#646765;

    
`
export const ImgDiv = styled.div`
width: 100%;
height: 100vh;
overflow: hidden;
img{
        width: 100%;
        top: -400px;
        position: relative;
        @media(max-width: 1300px){
        top: -300px;
        }
        @media(max-width: 900px){
        top: -200px;
        }
        @media(max-width: 600px){
        top: 0;
        }
       
    }

`
export const AboutDiv = styled.div`
    width: 70%;
    margin: 70px auto 50px auto;
    text-align: center;

    span{
        color: white;
        font-size: 45px;
        width: 100%;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        @media(max-width: 350px){
            font-size: 35px;
        }
    }
    p{
        text-align: left;
        margin-top: 50px;
        text-align: center;

        span{
            font-size: 20px;
            line-height: 40px;
            font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
            @media(max-width: 450px){
            font-size: 18px;
            }   
        }
    }

`