import React, { useState, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form';
import { ProductsDiv, ProductsInfoDiv, DoneDiv, EditUsersDiv, EditUsersInput, EditUsersButton, EditUsersInputCheck, SortDiv, SearchUsers, SearchDivIconDiv } from "./styled";
import { FaEdit } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { FaSearch } from "react-icons/fa";
import { Link } from 'react-router-dom';


export const AllAdmins = () => {
    const itemsPerPage = 20;
    const [users, setUsers] = useState([])
    const [editUserIndex, setEditUserIndex] = useState(null);
    const [editBuyIndex, setEditBuyIndex] = useState(null);
    const [searchUser, setSerachUser] = useState('')
    const [visibleItems, setVisibleItems] = useState(itemsPerPage);
    const containerRef = useRef(null);
    const { register, handleSubmit } = useForm();
    useEffect(() => {
        const fetchData = async () => {
            const req = await fetch('https://server.mayyanart.com/admins');
            const res = await req.json()
            if (req.ok) {
                setUsers(res.admins)
            } else console.log('не удалось получить список пользователей');
        }
        fetchData()
    }, [editUserIndex])

    const deleteUser = async (i) => {
        try {
            const UserDelete = users[i];
            const req = await fetch(`https://server.mayyanart.com/admins/${UserDelete._id}`, {
                method: "DELETE",
            });

            if (req.ok) {
                const updateUsers = users.filter((_, index) => index !== i);
                setUsers(updateUsers);
            } else {
                console.error("Не удалось удалить обзор");
            }
        } catch (err) {
            console.log(err);
        }
    };
    const toggleEditUsers = (index) => {
        setEditUserIndex((e) => (e === index ? null : index));
        setEditBuyIndex(false)
    };
    const toggleBuyUsers = (index) => {
        setEditBuyIndex((e) => (e === index ? null : index));
        setEditUserIndex(false)
    };
    
    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const isAtBottom =
                container.scrollTop + container.clientHeight >= container.scrollHeight - 20;


            if (isAtBottom) {
                setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
            }
        }
    };

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    console.log(users);
    return (
        <>

            <ProductsDiv ref={containerRef}>
                <SortDiv>
                    <SearchUsers placeholder='search' onChange={(e) => setSerachUser(e.target.value)} />
                    <SearchDivIconDiv>
                        <FaSearch />
                    </SearchDivIconDiv>
                </SortDiv>
                {users?.filter(
                    (e) =>
                        e.login?.toLowerCase().includes(searchUser?.toLowerCase())
                )?.slice(0, visibleItems).map((e, i) => {
                    return (
                        <ProductsInfoDiv key={i}>
                            <DoneDiv>
                                <button onClick={() => deleteUser(i)} style={{width:"30px" , height:"30px"}}> {<RxCross1 />}</button>
                            </DoneDiv>
                            <p>login: {e.login}</p>
                            
                        </ProductsInfoDiv>
                    );
                })}
            </ProductsDiv>
        </>
    );
}