import React, { useEffect, useState, useRef } from "react";
import { useForm } from 'react-hook-form';
import { MdOutlineDoneAll } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { InStockP, DoneDiv, EditProductButton, EditImgInput, EditProductDiv, EditProductInput, ProductsDiv, ProductsInfoDiv, SortSpan, SortDiv, SearchProducts, SearchDivIconDiv } from "./styled";
import { FaEdit } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { FaSearch } from "react-icons/fa";

export const MyBGCList = React.memo(() => {
  const { register, handleSubmit } = useForm();
  const itemsPerPage = 20;
  const [products, setProducts] = useState([]);
  const [editProductIndex, setEditProductIndex] = useState(null);
  const [sortProducts, setSortProducts] = useState("minMax");
  const [sortProductsBoolean, setSortProductsBoolean] = useState(true);
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  const [searchProducts, setSerachProducts] = useState('')

  const containerRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://server.mayyanart.com/bgc-get-admin")
        const res = await req.json();
        setProducts(res.img);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();

  }, [editProductIndex]);

  const deleteProduct = async (i) => {
    try {
      const ProductsToDelete = products[i];
      const req = await fetch(`https://server.mayyanart.com/bgc/${ProductsToDelete._id}`, {
        method: "DELETE",
      });

      if (req.ok) {
        const updatedproducts = products.filter((_, index) => index !== i);
        setProducts(updatedproducts);
        setEditProductIndex(null);
      } else {
        console.error("Не удалось удалить обзор");
      }
    } catch (err) {
      console.log(err);
    }
  };


  const toggleEditProduct = (index) => {
    setEditProductIndex((e) => (e === index ? null : index));
  };



  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const isAtBottom =
        container.scrollTop + container.clientHeight >= container.scrollHeight - 20;


      if (isAtBottom) {
        setVisibleItems((prevVisibleItems) => prevVisibleItems + itemsPerPage);
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);
  return (
    <>

      <ProductsDiv ref={containerRef}>


        {products?.map((e, i) => (
          <ProductsInfoDiv key={e._id}>

            <DoneDiv>
              <span> </span>
              <button onClick={() => deleteProduct(i)}> {<RxCross1 />}</button>
            </DoneDiv>
            <p>էջ: {e.name}</p>
            <p>նկար: <a href={`https://server.mayyanart.com/uploads/${e.src}`} target="_blank">{e.src}</a></p>
          </ProductsInfoDiv>

        ))}

      </ProductsDiv>
    </>
  );
});
