import React, { useState, useEffect } from "react";
import { ImgDiv, MainDiv, ProductsDiv, ProductsImgDiv, SingleProducts, TitleText } from "./styled";
import { useTranslation } from 'react-i18next';

export const NftPage = () => {


    const [obj, setObj] = useState()
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 900);
    const [lang, setLang] = useState();
    const [imageSizes, setImageSizes] = useState([]);
    const [img , setImg] = useState()
    const {t} = useTranslation()
    useEffect(() => {
        setLang(localStorage.getItem('lang'))
    }, [t])
    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchData = async () => {
            try {
                const req = await fetch("https://server.mayyanart.com/admin/nft")
                const res = await req.json()

                setObj(res.reverse())
                loadImageSizes(res);

            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
        const fetchData1 = async () => {
            let obj = {
                name: "products"
            }
            try {
                const req = await fetch("https://server.mayyanart.com/bgc-get", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                })
                const res = await req.json();
                setImg(res.img);
            } catch (err) {
                console.log(err);
            }
        };
      
        fetchData1();
    }, [])
    const [isVisible, setIsVisible] = useState(Array(obj?.length).fill(false));

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY + window.innerHeight;
            obj?.forEach((_, index) => {
                const elementPosition = document.getElementById(`products-${index}`)?.offsetTop;
                setIsVisible(prevState => {
                    const newState = [...prevState];
                    newState[index] = scrollPosition >= elementPosition;
                    return newState;
                });
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [obj]);

    const loadImageSizes = (products) => {
        const sizes = products.map(product => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve({ width: img.width, height: img.height });
                img.onerror = reject;
                img.src = `https://server.mayyanart.com/uploads/${product.imgs[0]}`;
            });
        });

        Promise.all(sizes).then(sizes => setImageSizes(sizes)).catch(console.error);
    };
   
    return (
        <>
            <MainDiv>

                <ImgDiv>
                    {img && <img src={`https://server.mayyanart.com/uploads/${img?.src}`}/>}
                </ImgDiv>
                <TitleText>
                    <span>
                        NFT
                    </span>
                </TitleText>
                <ProductsDiv>
                    {obj?.map((e, i) => {
                        console.log(isVisible[i]);
                        return  (
                            <ProductsImgDiv
                                id={`products-${i}`}
                                key={e + i}
                                style={{
                                    opacity: isVisible[i] ? 1 : 0,
                                    transform: isVisible[i] ? 'translateX(0)' : (i % 2 === 0 ? 'translateX(-30px)' : 'translateX(30px)'),
                                    transition: 'opacity 1.5s, transform 1.5s'
                                  }}
                                $text={lang === "ru" && e.titleRU || lang === "en" && e.titleEN || lang === "am" && e.titleAM}
                                to={`/product/id/${e._id}`}
                            >
                                <img src={`https://server.mayyanart.com/uploads/${e.imgs[0]}`} alt={e.titleEN} />
                            </ProductsImgDiv>
                        )
                    })}
                </ProductsDiv>
            </MainDiv>
        </>
    );
};
