import styled from "styled-components";


export const AdminLoginDiv = styled.div`
    width: 100%;
    height: 100vh;
    background-color: #e9ecef;
    position: relative;
`
export const AdminLoginForm = styled.div`
    width: 300px;
    height: 300px;
    background-color: white;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    text-align: center;
    padding-top: 30px;
    border-radius:20px;

`

export const AdminPanelInput = styled.input`
    width: 80%;
    height: 40px;
    border-radius: 10px;
    border: none;
    outline: none;
    padding-left: 15px;
    background-color: #e5e3e3;
    margin-top: 10px;

`
export const AdminPanelButton = styled.button`
	width: 80%;
	height: 40px;
	border-radius:50px;
	border:none;
	font-size: 19px;
	transition: 0.5s;
	background-color: #5cb85c;
    margin-top: 30px;
	&:hover{
	box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
	transform: scale(1.05);	};
	@media(max-width:270px){
		font-size:11px;
	}
`














export const AdminDiv = styled.div`
position: absolute;
width: 100%;
z-index: 99999999999999999;
background-color: white;
margin-top: -100px;
height: 100vh;
`


export const AdminHeader = styled.div`
height: 100px;
width: 100%;
background-color: #3f51b5;

`


export const AdminHeaderProfileImg = styled.img`
    width: 45px;
    height: 45px;
    margin-top: 45px;
    margin-left: 5px;
    cursor: pointer;
    float: right;
    margin-right: 30px;
    &:hover{
        opacity: 0.8;
    }
`
export const AdminBody = styled.div`
    width: 100%;
    background-color: white;
`

export const AdminBodyDivsAll = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: #eee;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    
    
`

export const AdminBodyDivs = styled.div`
width: 42%;
min-height: 300px;
background-color: white;
margin-top: 20px;
display: flex;
justify-content: space-around;
border-radius: 20px;
@media(max-width: 971px){
		width:100%;
	}
    @media(max-width: 536px){
		display:inline;
        padding: 10px;


	}


`

export const AdminBodyDiv = styled.div`
    width: 30%;
    height: 250px;

    margin-top: auto;
    margin-bottom: auto;
    background-color: #eee;
    border-radius: 20px;
    cursor: pointer;
    @media(max-width: 537px){
		width:80%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 10px;

	}

`

export const AdminBodyDivImgDiv = styled.div`
    width: 100%;
    height: 60%;
    background-color: white;
    text-align: center;
    background-color: #eee;
    border-radius: 20px;

`

export const AdminBodyImg = styled.img`
    width: 100px;
    height: 100px;
    margin-top: 20px;
    cursor: pointer;


`

export const AdminBodyTextDiv = styled.div`
    width: 100%;
    height: 40%;
    text-align: center;

`

export const AdminBodyText = styled.span`
    font-size: 20px;
`

export const RamkaBgcDiv = styled.div`
    width: 100%;
    height:110vh;
    background-color: black;
    opacity: 0.7;
    position: fixed;
`
export const RamkaDiv = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    height: 700px;
    width: 400px;
    text-align: center;
`

export const TitleDiv = styled.div`
    width: 100%;
    padding: 5px;
    text-align: center;
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`
export const AddProductFormDiv = styled.form`
    width: 100%;
    height: 90%;
    background-color: white;
    padding: 0 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
`


export const AddProductsInput  = styled.input`
    width: 100%;
    height: 40px;
    border: none;
    outline:none;
    border-radius: 20px;
    padding: 10px;
    background-color: #eee;
    margin-top: 10px;
`
export const AddProductsInputCheck  = styled.input`
    width: 30px;
    height: 30px;
    border: none;
    outline:none;
    border-radius: 20px;
    margin-top: 10px;
`


export const AddImgInput = styled.input`
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  width: 100%;
  margin-top: 20px;
`

export const AddProductTextArea = styled.textarea`
    width: 100%;
    height: 80px;
    padding: 10px;
    background-color: #eee;
    border: none;
    outline: none;
    border-radius: 20px;
    resize: none;
    margin-top: 10px;
`

export const AddProductsButton = styled.button`
	width: 80%;
	height: 40px;
	margin-top:20px;
	border-radius:50px;
	border:none;
	font-size: 19px;
	transition: 0.5s;
	background-color: #5cb85c;
	&:hover{
	box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
	transform: scale(1.05);	};

`


export const CloseDiv = styled.div`
    width: 100%;
    height: 150vh;
    position: fixed;
    background-color: black;
    opacity: 0.7;
`