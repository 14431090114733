import React, { useState , useEffect } from "react";
import { ScrollToTopDiv } from "./styled";
import { IoMdArrowRoundUp } from "react-icons/io";

export const ScrollToTop = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleScroll = () => {
        if (window.pageYOffset > 100) { // Установите значение по вашему усмотрению
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            {isVisible && 
                <ScrollToTopDiv onClick={scrollToTop}>
                    <span>
                        <IoMdArrowRoundUp />
                    </span>
                </ScrollToTopDiv>
            }
        </>
    )

}