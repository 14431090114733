import styled from "styled-components";

export const Item = styled.div`
  overflow: hidden;
  height: 250px;
  width: 100%;
  border-radius: 30px;
  border: 1px solid;
  margin: 0 15px;
  background: linear-gradient(0deg, #071d8f, 49%, transparent);

`;

export const NameWriter = styled.div`
  width: 100%;
  height: 45px;
  font-weight: bold;
  font-size: 27px;
  padding: 10px 20px;
`;

export const Position = styled.div`
  width: 100%;
  height: 30px;
  font-weight: bold;
  padding-left: 20px;
  padding-top: 5px;
`;

export const Stars = styled.div`
  width: 100%;
  height: 25px;
  padding-left: 20px;
  color: yellow;
  font-size: 21px;
`;

export const Desc = styled.div`
  width: 90%;
  height: 150px;
  padding-left: 20px;
  font-size: 17px;
  padding-top: 10px;
`;


export const RamkaDiv = styled.div`
position: fixed;
left: 0;
top: 0;
right: 0;
bottom: 0;
background-color: #191923;
width: 400px;
height: 500px;
z-index: 999991;
opacity: 1;
transition: opacity 0.5s ease-in-out;
margin: auto;
border-radius:50px;
text-align: center;
padding-top: 20px;
	display: ${({ openReviews }) => openReviews ? 'none' : 'block'};
	@media(max-width: 344px){
		width: 100%;


	}
`
export const TitleReviews = styled.h4`
color: #9ba49b;;
margin-top: 10px;
`


export const ReviewInput = styled.input`
	width: 80%;
	height: 40px;
	border-radius:10px;
	padding-left: 10px;
	background-color: #e5e3e3;
	border: none;
	outline: none;
	margin-top: 20px;

`

export const ReviewTextarea = styled.textarea`
	width: 80%;
    height: 100px;
	border-radius:10px;
	padding-left: 10px;
	background-color: #e5e3e3;
	border: none;
	outline: none;
	margin-top: 20px;
  padding: 10px;
  resize: none;

`

export const Star = styled.div`
display: flex;
margin-left: 40px;
`


export const StarSpan = styled.div`
    font-size: 30px;
    width: 30px;
    cursor: pointer;

`
export const ReviewButton = styled.button`
	width: 300px;
	height: 40px;
	margin-top:50px;
	border-radius:50px;
	border:none;
	font-size: 19px;
	transition: 0.5s;
	background-color: #0787a5;
  color: white;
  font-weight: bold;
  cursor: pointer;
	&:hover{
	box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
	transform: scale(1.05);	};
@media(max-width: 500px){
  width: 80%;
  margin: 0 auto;
}
`


export const BGCdiv = styled.div`
z-index: 99999;
position: fixed;
width: 100%;
height: 120%;
top:-60px;
background-color: black;
opacity: 0.7;
`
