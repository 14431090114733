import React , {useState , useEffect} from "react";
import { BodyDiv, CloseDiv, HeaderDiv, MainDiv , FooterDiv, MyButton } from "./styled";
import inst from "../../assets/images/inst.png"
import { useTranslation } from 'react-i18next'

export const AlertInstagram = () => {
    const [closeDiv , setCloseDiv] = useState(false)
    const { t } = useTranslation()

    useEffect(() => {
        if(!localStorage.getItem("alertInst")){
            setCloseDiv(true)
        }
    })
    const hendleCloseDiv = () => {
        setCloseDiv(false)
        localStorage.setItem("alertInst" , false)
    } 

    
    return(
        <>
            {closeDiv && <MainDiv>
                <CloseDiv onClick={hendleCloseDiv}>
                    X
                </CloseDiv>
                <HeaderDiv>
                    <img src={inst}/>
                </HeaderDiv>
                <BodyDiv>
                    <span>
                      {t("Hi there")}
                     👋, {t("follow me on instagram for more art")}
                    </span>
                    <br />  

                </BodyDiv>
                <FooterDiv>
                    <MyButton>
                        @instagram
                    </MyButton>
                </FooterDiv>
            </MainDiv>}
        </>
    )
}