import styled from "styled-components"


export const ProductsDiv = styled.div`
    background-color: white;
    position: fixed;
    padding: 20px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 70%;
    height: 600px;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 20px;
    word-wrap: break-word;
  z-index: 2;
    &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

  &::-webkit-scrollbar-track {
    background-color: #555;
  }
  @media(max-width:500px){
    width: 80%;
  }
    
`

export const ProductsInfoDiv = styled.div`
    width: 100%;
    height:70px;
    background-color: white;
    margin-top: 20px;
    padding: 10px;
    overflow: auto;
    word-wrap: break-word;
    border-bottom: 2px solid #e5e5e5;

  position: relative;
    &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #333;
  }

  &::-webkit-scrollbar-track {
    background-color: #555;
  }
`

export const DoneDiv = styled.div`
position: absolute;
right: 10px;
top: 10px;

`

export const EditProductDiv = styled.form`
    width: 100%;
    min-height: 100px;
    
`
export const SortDiv = styled.div`
  width: 100%;
  min-height: 30px;
  padding: 20px;
  position: relative;
`

export const SortSpan = styled.span`
  cursor: pointer;
  font-weight: bold;
`

export const EditUsersDiv = styled.div`
width: 100%;
min-height: 100px;

`

export const EditUsersInput = styled.input`

outline: none;
border-radius: 20px;
padding-left: 10px;
margin-top: 5px;
`
export const EditUsersInputCheck = styled.input`
margin-top: 10px;
width: 15px;
height: 15px;
`
export const EditUsersButton = styled.button`
  margin-top: 10px;
`
export const SearchUsers = styled.input`
  width: 300px;
  height: 40px;
  border-radius:20px;
  outline: none;
  padding-left: 10px;
  @media(max-width:500px){
    width: 100%;
  }
`
export const SearchDivIconDiv = styled.div`
  position: absolute;
  font-size: 25px;
  top: 27px;
  left: 280px;
  cursor: pointer;
  @media(max-width:500px){
    left: 75%;
  }

`