import styled from "styled-components";
import { Link } from "react-router-dom";
import Aliq from "../../assets/images/yelaliq.png";

export const FooterDiv = styled.div`
  width: 100%;
  position: relative;
  min-height: 100px;
  padding: 20px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

`;

export const IconsForm = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
`;

export const Icons = styled.li`
  list-style: none;
`;

export const LinkA = styled(Link)`
  font-size: 2em;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  &:hover {
    transform: translateY(-10px);
  }
`;

export const UlMenu = styled.ul`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  @media(max-width: 663px){
    flex-direction: column;
    gap: 10px;
  }
`;

export const MenuLi = styled.li`
  list-style: none;
`;

export const MenuLinks = styled(Link)`
  font-size: 23px;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  &:hover {
    transform: translateY(-10px);
  }
`;

export const P = styled.p`
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 18px;
  color: white;
  @media(max-width: 646px){
    width: 70%;
  }
`;

export const Waves = styled.div``;

export const WaveMini1 = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${Aliq});
  background-size: 1000px 100px;
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
  @keyframes animateWave {
    0% {
      background-position-x: 1000px;
    }
    100% {
      background-position-x: 0px;
    }
  }
`;

export const WaveMini2 = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${Aliq});
  background-size: 1000px 100px;
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave2 4s linear infinite;
  @keyframes animateWave2 {
    0% {
      background-position-x: 0px;
    }
    100% {
      background-position-x: 1000px;
    }
  }
`;

export const WaveMini3 = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${Aliq});
  background-size: 1000px 100px;
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
  @keyframes animateWave {
    0% {
      background-position-x: 1000px;
    }
    100% {
      background-position-x: 0px;
    }
  }
`;

export const WaveMini4 = styled.div`
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(${Aliq});
  background-size: 1000px 100px;
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave2 3s linear infinite;
  @keyframes animateWave2 {
    0% {
      background-position-x: 0px;
    }
    100% {
      background-position-x: 1000px;
    }
  }
`;
