import styled from "styled-components";


export const Panel = styled.div`
    width: 80px;
    height: 100vh;
    background-color: white;
    position: fixed;
    float: left;
    z-index:9999999;
    transition: 0.5s;
    left: ${({$openLeft}) => $openLeft ? 0 : "-80px"};
    z-index: 99999999999999;

`

export const HoverMe = styled.div`
    width: 50px;
    height: 50px;
    padding: 0 7px 0 0;
    background-color: white;
    text-align: center;
    position: fixed;
    transition: 0.5s;
    color: #b0b0b0;
    top: 50%; 
    left: ${({$openLeft}) => $openLeft ? "88px" : "10px"};   
    transform: translate(-50%, -50%); 
    z-index:99999999;
    border-radius: 0 50% 50% 0;
    writing-mode: vertical-rl; 
    text-orientation: upright; 
    cursor: pointer;
    z-index: 99999999999999;
    font-size: 25px;
    font-weight: bold;


`

export const CloseDiv = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: black;
    opacity: 0.7;
    z-index: 999999;
`

export const ImgDiv = styled.div`
    width: 50px;
    display: flex;
    flex-direction: column;
    text-align: center;
        border-radius: 50%;
    align-items: center;
    position: absolute;
    top: 50%; 
    gap: 20px;

    transform: translate(10px, -50%); 

    img{
        width: 50px;
        cursor: pointer;
        transition: 0.5s;
        border-radius: 50%;
        &:hover{
            transform:scale(1.2)
        }
    }
`