import { useEffect, useState } from "react";
import React from "react";
import { AboutDiv, ImgDiv, MainDiv } from "./styled";
import bgc from "../../assets/images/bgc/bgc1.jpg"
import { useTranslation } from 'react-i18next'

export const About = () => {
    const [value, setValue] = useState()
    const [img, setImg] = useState()
    const [lang, setLang] = useState()
    const { t } = useTranslation()
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData1 = async () => {
            let obj = {
                name: "about"
            }
            try {
                const req = await fetch("https://server.mayyanart.com/bgc-get", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(obj)
                })
                const res = await req.json();
                setImg(res.img);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData1();
        const fetchData = async () => {
            try {
                const req = await fetch("https://server.mayyanart.com/about-get")
                const res = await req.json()
                setValue(res.about)
                // for(let i = 0 ; i < res.home.length ; i++){
                //     myF()
                // }
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()

    }, [])

    useEffect(() => {
        setLang(localStorage.getItem('lang'))
    }, [t])
    console.log(img);
    return (
        <MainDiv>
            <ImgDiv>
                {img && <img src={`https://server.mayyanart.com/uploads/${img?.src}`} />}

            </ImgDiv>
            {value?.map((e) => {
                if (lang == "ru" && e.activ) {
                    return (
                        <AboutDiv>
                            <span>{e.titleRU}</span>
                            <p>
                                <span>{e.textRU}</span>

                            </p>
                        </AboutDiv>
                    )
                } else if (lang == "en" && e.activ) {
                    return (
                        <AboutDiv>
                            <span>{e.titleEN}</span>
                            <p>
                                <span>{e.textEN}</span>

                            </p>
                        </AboutDiv>
                    )
                } else if (lang == "am" && e.activ) {
                    return (
                        <AboutDiv>
                            <span>{e.titleAM}</span>
                            <p>
                                <span>{e.textAM}</span>

                            </p>
                        </AboutDiv>
                    )
                }


            })}

        </MainDiv>
    )
}