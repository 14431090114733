import styled from "styled-components";

export const RamkaDiv = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-height: 100vh;
    width: 400px;
    text-align: center;
    margin-top: 10px;
    overflow-y: auto;
    z-index: 2;
    @media(max-width:600px){
        width: 80%;
    }
`

export const TitleDiv = styled.div`
    width: 100%;
    padding-top: 20px;
    text-align: center;
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
`

export const AddProductFormDiv = styled.form`
    width: 100%;
    min-height: 90%;
    background-color: white;
    padding: 50px 10px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
`


export const AddProductsInput  = styled.input`
    width: 100%;
    height: 40px;
    border: none;
    outline:none;
    border-radius: 20px;
    padding: 10px;
    background-color: #eee;
    margin-top: 20px;
`
export const AddProductsSelect  = styled.select`
    width: 100%;
    height: 40px;
    padding:  0 0 0 10px;
    border: none;
    outline:none;
    border-radius: 20px;
    background-color: #eee;
    margin-top: 10px;
`
export const AddProductsInputCheck  = styled.input`
    width: 30px;
    height: 30px;
    border: none;
    outline:none;
    border-radius: 20px;
    margin-top: 10px;
`


export const AddImgInput = styled.input`
  content: 'Select some files';
  display: inline-block;
  background: linear-gradient(top, #f9f9f9, #e3e3e3);
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 700;
  font-size: 10pt;
  width: 100%;
  margin-top: 10px;
`

export const AddProductTextArea = styled.textarea`
    width: 100%;
    height: 80px;
    padding: 20px 0  0 20px;
    background-color: #eee;
    border: none;
    outline: none;
    border-radius: 20px;
    resize: none;
    margin-top: 10px;
`

export const AddProductsButton = styled.button`
	width: 80%;
	height: 40px;
	border-radius:50px;
	border:none;
	font-size: 19px;
	transition: 0.5s;
	background-color: #5cb85c;
    margin-top: 20px;
    cursor: pointer;
	&:hover{
	box-shadow: rgb(38, 57, 77) 0px 10px 15px -5px;
	transform: scale(1.05);	};

`

