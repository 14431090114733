import React, { useState, useEffect } from "react";
import { Home, Contact, ProductsCard, AllProducts } from "./containers";
import { Menu, Footer, Navigation, AlertInstagram , ScrollToTop} from "./components";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import GlobalStyles from "./GlobalStyles";
import { ToastContainer } from "react-toastify"
import 'react-toastify/dist/ReactToastify.css';
import { AdminPanel } from "./admin";
import { ProductStr } from "./containers/ProductStr";
import { About } from "./containers/about";
import { DailyScathes } from "./containers/Daily-scathes";
import { WallArt } from "./containers/Wall-art";
import { NftPage } from "./containers/NFT";
import { PaintingsPage } from "./containers/paintings";
import { LeftPanel } from "./components/leftPanel";
import translationEN from "./langs/en/translation.json"
import translationRU from "./langs/ru/translation.json"
import translationAM from "./langs/am/translation.json"
import { LoadingPage } from "./containers/loadingPage";
import "./style.css"

export const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: translationEN,
      },
      ru: {
        translation: translationRU,
      },
      am: {
        translation: translationAM,
      }
    },
    lng: localStorage.getItem("lang") || "en",
    fallbackLng: "ru",
    interpolation: {
      escapeValue: false,
    },
  });
  useEffect(() => {

    setTimeout(() => {
    setIsLoaded(true);

    } , 1500)
  }, []);

  return (
    <>
      {isLoaded ? (
        <Router>
          <LeftPanel />

          <ScrollToTop />
          <GlobalStyles />
          <Menu />
          <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{zIndex : "9999999999999999999"}}
      />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/daily-scathes" element={<DailyScathes />} />
            <Route path="/wall-art" element={<WallArt />} />
            <Route path="/nft" element={<NftPage />} />
            <Route path="/paintings" element={<PaintingsPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/admin" element={<AdminPanel />} />
            <Route path="/products" element={<AllProducts />} />
          <Route path="/product/id/:id" element={<ProductStr />} />

          </Routes>
          <AlertInstagram />
          <Footer />

        </Router>
      ) : (
        <LoadingPage />
      )}
    </>
  );
};
