import styled from "styled-components";


export const ScrollToTopDiv = styled.div`
    width: 60px;
    height: 60px;
    position: fixed;
    bottom:20px;
    right: 20px;
    border-radius: 50%;
    border: 2px solid white;
    background-color:none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 100000000;
    transition: 0.5s;
    span{
        font-size: 33px;
        color: #b0b0b0;
        margin-top: 8px;
    }
    &:hover{
        transform: scale(1.2);
        background-color: white;
        span{
            color: black;
        }
    }
`